import React from 'react';
import PropTypes from 'prop-types';
import './Error404.css';

const Error404 = () => (
  <div className="Error404">
    <h1>Erreur 404</h1>
    <p>Page non retrouvée</p>
    <p>Vous serez redirigé vers la page d'accueil dans 3 secondes</p>

  </div>
);

Error404.propTypes = {};

Error404.defaultProps = {};

export default Error404;
