import React from 'react';
import './Modal.css';

const Modal = ({close}) => (
  <div className="modal-container">
    <div className="overlay fixed"></div>
    <div className="modal fixed box">
      <div className="modal-header relative">
        <h2>Bienvenue chers développeurs</h2>
        <i className="fas fa-times absolute" onClick={close}></i>
      </div>
      <div className="modal-content">
        Dès aujourd'hui, commencez à apprendre avec Mudey. Béneficier de 90% de réduction
        avec le coupon ci-dessous.
      </div>
      <div className="modal-footer flex">
        <button className="flex-1">Coupon : 082021</button>
      </div>
    </div>
  </div>
);

export default Modal;
