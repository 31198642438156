import React, { Component, Fragment } from 'react';
import Slider from '../Slider/Slider'
import PropTypes from 'prop-types';
import './Finance.css';

export default class Finance extends Component {

  constructor(props){
    super(props)
    this.slider = {
      title: "Comment financer sa formarion avec Mudey",
      content: `Optez pour une solution d'autofinancement de votre formation professionnelle pour évoluer et gagner en compétences.`,
      imageUrl: 'url("/assets/images/cover-1.svg" )'
    }
  }

  componentDidMount(){
    window.scrollTo(0,0)
  }
  componentDidUpdate(){
    window.scrollTo(0,0)
  }
  render() {

    return (
      <Fragment>
        <Slider slider={this.slider}/>
        <div className="content-title tc">
          <h2>Optez pour Mudey, votre partenaire compétences</h2>
          <p>Choisissez parmi 1 500 vidéos de cours en ligne. De nouveaux cours sont ajoutés tous les mois</p>
        </div>
        <div className="page flex gap-20 wrap aic p-20">
          <p>
          Depuis 2017, Mudey contribue à la formation professionnelle 
          de plus de 5 000 apprenants en France et à travers le Monde. 
          Elle offre aujourd'hui plus de 20 formations IT 100 % en français 
          et qui répondent aux exigences de nos apprenants. 
          Faire confiance à Mudey, c'est avoir un cursus de formation adapté à vos besoin,
          et qui répond aux exigences des organismes financeurs et aux critères qualité 
          de pôle emploi.

          </p>
        </div>
      </Fragment>
    )
  }

}