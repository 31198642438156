import React, { Fragment } from 'react';
import './Slider.css';

const Slider = ({slider}) => (
  <Fragment>
    <div className="slider" style={{backgroundImage: slider.imageUrl}}>
    </div>
    <div className="slider-content" >
        <h2>{slider.title }</h2>
        <p>
          {
            slider.content
          }
        </p>
    </div>
  </Fragment>
);

export default Slider;
