import React from 'react';
import PropTypes from 'prop-types';
import './FooterList.css';

const FooterList = (props) => (
  <div className="footer-list">
    {/* {
    props.links.map(
      (link, index)=>{
        return <div className="footer-item" key={index}><a href={link.link} target="_blank"> {link.name}</a></div>
      }
    )
    } */}
  </div>
);

FooterList.propTypes = {};

FooterList.defaultProps = {};

export default FooterList;
