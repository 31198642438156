

export class httpClient {

    async get(url, headers = {}) {
        const response = await fetch(url, {
            method: "GET",
            headers: headers
        })
        if (!response.ok) {
            throw new Error("Request Error ")
        }

        return await response.json()
    }

    async post(
        url, 
        data, 
        headers = {"Accept": "application/json", "Content-Type": "application/json"
    }) {
        const response = await fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            throw new Error("Request Error ")
        }

        return await response.json()
    }

    async put(
        url, 
        data, 
        headers = {"Accept": "application/json", "Content-Type": "application/json"
    }) {
        const response = await fetch(url, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data)
        })
        if (!response.ok) {
            throw new Error("Request Error ")
        }

        return await response.json()
    }

    async delete(
        url, 
        headers = {}) {
        const response = await fetch(url, {
            method: "DELETE",
            headers: headers
        })
        if (!response.ok) {
            throw new Error("Request Error ")
        }

        return await response.json()
    }
    
}