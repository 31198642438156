import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './Home.css';
import Slider from '../Slider/Slider';
import Container from '../Container/Container';

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.slider = {
      title: "La promotion se termine aujourd'hui",
      content: `Dernier jour pour profiter de notre promotion.
    Ayez le goût de l'apprentissage et obtenez des cours à partir de 12,99 € seulement.`,
      imageUrl: 'url("/assets/images/cover-1.svg" )'
    }
  }
  render() {
    return (
      <Fragment>
        <Slider slider={this.slider} />
        <Container {...this.props} />
      </Fragment>
    )
  }

}