import React, { Component, Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getCategory, searchCourse } from '../../api/api-courses';
import Banner from '../Banner/Banner';
import './Header.css';
import EntityManager from '../../api/entity';

export default class Header extends Component {

    entity = new EntityManager()

    constructor(props){
        super(props)
        this.subMenu = React.createRef() 
        this.autoComplete = React.createRef() 
        this.autoCompleteMobile = React.createRef() 
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
        this.handleOver = this.handleOver.bind(this)
        this.closePanelAutocomplete = this.closePanelAutocomplete.bind(this)
        this.handleChangeTag = this.handleChangeTag.bind(this)
        this.state = {categories: [], coursesFilter: []}
    }

    handleOver(){
        this.closePanelAutocomplete()
        this.subMenu.current.classList.remove("none")
    }
    handleMouseLeave(){
        this.subMenu.current.classList.add("none")
    }
    async componentDidMount(){
        const data = await this.entity.getDatas("category")
        if(data.isSuccess){
          this.setState({...this.state, categories: data.results})
        }
    }
    async handleChangeTag(event){
        this.handleMouseLeave()
        const tag = event.target.value.trim()
        if(tag){
            const data = await this.entity.searchData("course","name="+tag)
            // const courses = searchCourse(tag)
            this.autoComplete.current.classList.remove("none")
            this.autoCompleteMobile.current.classList.remove("none")
            if(data.isSuccess && data.results.length){
                this.setState({...this.state,coursesFilter: data.results})  
            }else{
                const course = {
                    _id: Math.round(Math.random()*1522),
                    name: "Désolé, aucun cours de correspond au mot clé : "+tag+""
                }
                this.setState({...this.state,coursesFilter: [course]}) 

            }
            console.log(data);
        }else{
            this.autoComplete.current.classList.add("none")
            this.autoCompleteMobile.current.classList.add("none")
        }
        console.log(tag);

    }
    closePanelAutocomplete(){
        this.autoComplete.current.classList.add("none")
    }
    toogleSearch(){
        document.querySelector(".search-container").classList.toggle("none")
    }
    render(){
        return (
            <Fragment>
                <Banner />
                <header className="flex aic gap-10">
                    <div className="header-left flex-1 flex aic gap-20">
                        <i className="fas fa-bars none"></i>
                        <div className="logo cp"><Link to="/">Mudey</Link></div>
                        <i className="fas fa-search none" onClick={this.toogleSearch}></i>
                        <div className="nav-item flex-1 cp" onMouseOver={this.handleOver}>Catégories</div>
                    </div>
                    <div className="search-container flex column fixed none">
                        <div className="search-bar-container flex p-10 aic gap-10">
                            <i className="fas fa-search"></i>
                            <input type="search" className="flex-1" 
                            placeholder="Rechercher un cours "
                            onKeyUp={this.handleChangeTag}
                            onChange={this.handleChangeTag}
                            onMouseOver={this.handleChangeTag} 
                            />
                            <i className="fas fa-times" onClick={this.toogleSearch}></i>
                        </div>
                        <div className="autocomplete" ref={this.autoCompleteMobile}>
                        {
                            this.state.coursesFilter.length ?
                            this.state.coursesFilter.map(course =>{

                                return <a href={course.link} target="_blank" onClick={this.handleMouseLeave} key={course._id}>
                                            <div className="autocomplete-item flex aic gap-5"><i className="fas fa-search"></i>{ course.name}</div>
                                        </a> 
                            })
                            :
                            null
                        }
                        </div>
                    </div>
    
                    <div className="header-center flex-4 flex relative">
                        <i className="fas fa-search absolute"></i>
                        <input type="search" className="flex-1" placeholder="Rechercher un cours " 
                        onKeyUp={this.handleChangeTag}
                        onChange={this.handleChangeTag}
                        onMouseOver={this.handleChangeTag}
                        />
                        <div className="autocomplete absolute box none" ref={this.autoComplete}
                        onMouseLeave={this.closePanelAutocomplete}
                        >
                        {
                            this.state.coursesFilter.length ?
                            this.state.coursesFilter.map(course =>{

                                return <a href={course.link} target="_blank" onClick={this.handleMouseLeave} key={course._id}>
                                            <div className="autocomplete-item flex aic gap-5"><i className="fas fa-search"></i>{ course.name}</div>
                                        </a> 
                            })
                            :
                            null
                        }
                    </div>
                    </div>
                    <div className="header-right flex-1 flex jcc cp">
                        {/* <div className="nav-item">Ressources</div> */}
                    </div>
                    <div className="sub-menu categories absolute box none" onMouseLeave={this.handleMouseLeave} ref={this.subMenu}>
                        {
                            this.state.categories.map(category =>{

                                return <Link to={"/course/"+category.slug} onClick={this.handleMouseLeave} key={category.slug}>
                                            <div className="sub-menu-item">{ category.name}</div>
                                        </Link> 
                            })
                        }
                    </div>
                    
                </header>
            </Fragment>
        )
    }
};

