import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from "../Slider/Slider"
import './Employabilite.css';
import AsideComp from '../AsideComp/AsideComp';
import Loading from '../Loading/Loading';
import { getEmployements } from '../../api/api-courses';

export default class Employabilite extends Component {
  constructor(props) {
    super(props)
    this.slider = {
      title: "Boostez votre employabilité",
      content: `Pour booster votre employabilité, développez vos compétences avec Mudey. Des formations pointues sur les grands domaines d’expertise IT !`,
      imageUrl: 'url("/assets/images/cover-1.svg" )'
    }
    this.state = {employements: []}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const employements = getEmployements()
    this.setState({employements: employements})
  }
  componentDidUpdate() {
    window.scrollTo(0, 0)
  }

  render() {

    return (
      <Fragment>
        <Slider slider={this.slider} />
        <div className="content-title tc">
          <h2>Améliorer votre employabilité</h2>
          <p>Choisissez parmi 1 500 vidéos de cours en ligne. De nouveaux cours sont ajoutés tous les mois</p>

          <p>
          Pour booster votre employabilité, développez vos compétences avec Mudey !
          </p>
        </div>
        <div className="page flex gap-20 wrap aic">
         


        </div>
          {
            this.state.employements.length?
              this.state.employements.map(
                (employement, index)=> <AsideComp data={employement} right={!!(index%2)} key={index} />
                )
            :
            <Loading/>
          }        
        
      </Fragment>
    )
  }

}