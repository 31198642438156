import React, { Component, Fragment } from 'react';
import './Container.css';
import Course from './../Course/Course';
import EntityManager from '../../api/entity';




export default class Container extends Component {
  
  entity = new EntityManager()

  constructor(props) {
    super(props);
    this.state = {courses: []}
  }

  async componentDidMount(){
    const data = await this.entity.getDatas("course")
    if(data.isSuccess){
      this.setState({...this.state, courses: data.results})
    }
  }
  
  componentDidUpdate(prevProps){
    window.scrollTo(0,0)
    if(this.props.location !== prevProps.location) window.scrollTo(0,0);
  }
 
  

  render() {
    
    const {courses} = this.state

    return (
      <Fragment>
        <div className="content-title tc">
          <h2>Une large sélection de cours</h2>
          <p>Choisissez parmi 1 500 vidéos de cours en ligne. De nouveaux cours sont ajoutés tous les mois</p>
        </div>
        <div className="courses flex gap-20 wrap aic">
         {courses.map((course)=> <Course course={course} key={course._id}/>)}
        </div>
      </Fragment> 
      )
  }

};

