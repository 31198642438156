import React, { Component, Fragment } from 'react';
import './Course.css';
export default class Course extends Component{

    constructor(props){
        super(props)
        this.coupon = "082021"
    }

    render(){
        const { course } = this.props
        // console.log({course});
        return (
            <Fragment>
              <a href={course.link+"?couponCode="+course.coupon_code || "/"} target="_blank">
                          <div className="courses-item">
                              <div className="courses-illustration">
                                  <img src={course.imageUrl} alt=""/>
                              </div>
                              <div className="courses-description flex flex-1 column gap-5">
                                  <div className="course-name">
                                      <h3>{course.name}</h3>
                                  </div>
                                  <div className="course-author">Formateur : {course.authorFullName}</div>
                                  <div className="course-reviews flex gap-10">
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <span>({course.review_count} avis)</span>
                                  </div>
                                  <div className="course-price flex gap-10 aic">
                                      <div className="course-sold-price">{(course.solde_price)?.toFixed(2)}</div>
                                      <div className="course-regular-price"> <del>{(course.regular_price)?.toFixed(2)}</del> </div>
                                  </div>
                                  <div className="course-status">
                                        {course.course_status ? course.course_status : null}
                                  </div>
                              </div>
                          </div>
                      </a>
            </Fragment>
          )
    }
}
