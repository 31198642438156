import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { getCoursesByCategory } from '../../api/api-courses';
import Course from '../Course/Course';
import './ProductByCategory.css';
import EntityManager from '../../api/entity';


export default class ProductByCategory extends Component {

  entity = new EntityManager()

  constructor(props) {
    super(props);
    this.state = { courses: null, redirect: false }
  }
  
  async componentDidMount() {
    this.category = this.props.match.params.category
    const data = await this.entity.getCourseByCategorySlug(this.category)
    console.log({data});
    if (!data.isSuccess) {
      this.setState({ ...this.state, redirect: true })
    }
    this.setState({ courses: data.results })
  }

  async componentDidUpdate(previousProps, previousState) {
    window.scrollTo(0, 0)
    if (previousProps !== this.props) {
      this.category = this.props.match.params.category
      console.log({ category: this.category });
      const data = await this.entity.getCourseByCategorySlug(this.category)
      if (!data.isSuccess) {
        this.setState({ ...this.state, redirect: true })
      }
      this.setState({ courses: data.results })
    }
  }

  // componentDidMount() {
  //   window.scrollTo(0, 0)
  //   const courses = getCoursesByCategory(this.category)
  //   if (!courses.length) {
  //     this.setState({ ...this.state, redirect: true })
  //   }
  //   this.setState({ courses: courses })
  // }



  render() {
    // if (this.state.redirect) {
    //   return <Redirect to="/error" />
    // }
    return (
      <Fragment>
        <div className="content-title tc">
          <h2>Une large sélection de cours</h2>
          <p>Choisissez parmi 1 500 vidéos de cours en ligne. De nouveaux cours sont ajoutés tous les mois</p>
        </div>
        <div className="courses flex gap-20 wrap aic">
          {
            this.state.courses
              ?
              this.state.courses.map((course) => <Course course={course} key={course._id} />)
              :
              <div>Aucun cours retrouvé dans la categorie recherchée</div>
          }
        </div>
      </Fragment>
    )
  }

};

