import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Banner.css';
import { rendDateer } from '@testing-library/react';

export default class Banner extends Component {
  constructor(props){
    super(props)
    this.endDate = new Date("Aug 30 00:00:00 2023");
    this.initBanner()
    this.state = {day: this.day, hour: this.hour, minutes: this.minutes, secondes: this.secondes}
  }
  
  componentDidMount(){
    //this.endDate.setDate(this.startDate.getDate()+ 8)
   this.timeId = setInterval(()=>{
      this.startDate = new Date();
      const total_secondes = (this.endDate - this.startDate)/1000
      this.day = Math.floor(total_secondes / (60 * 60 * 24));
      this.hour = Math.floor((total_secondes - (this.day * 60 * 60 * 24)) / (60 * 60));
      this.minutes = Math.floor((total_secondes - ((this.day * 60 * 60 * 24 + this.hour * 60 * 60))) / 60);
      this.secondes = Math.floor(total_secondes - ((this.day * 60 * 60 * 24 + this.hour * 60 * 60 + this.minutes * 60)));
     // console.log({day: this.day, hour: this.hour, minutes: this.minutes, secondes: this.secondes});
      this.setState({day: this.day, hour: this.hour, minutes: this.minutes, secondes: this.secondes})
    },1000)

  }
  componentWillUnmount(){
    clearInterval(this.timeId)
  }
  initBanner(){
    const total_secondes = (this.endDate - new Date())/1000
    this.day = Math.floor(total_secondes / (60 * 60 * 24));
    this.hour = Math.floor((total_secondes - (this.day * 60 * 60 * 24)) / (60 * 60));
    this.minutes = Math.floor((total_secondes - ((this.day * 60 * 60 * 24 + this.hour * 60 * 60))) / 60);
    this.secondes = Math.floor(total_secondes - ((this.day * 60 * 60 * 24 + this.hour * 60 * 60 + this.minutes * 60)));
  }
  render(){
    return(
    <div className="banner none">
      Promo | Encore {this.state.day} jour pour obtenir des cours qui vous intéressent, 
      à partir de 12,99 € seulement.
      <br /> Fin dans {
      this.state.day >1 ? `${this.state.day} jours ` : `${this.state.day} jour `}  
      {this.state.hour} h {" "}
      {this.state.minutes} min {" "}
      {this.state.secondes} s.
    </div>
  )};

}
