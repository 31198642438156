export function getCourses() {
    return [
        {
            _id: 1,
            name: "Clonez le site de cartographie Google Maps en JavaScript",
            description: "",
            slug: "",
            category: ["formation-frontend"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 18,
            imageUrl: "/assets/images/formation/clonez_google_maps_en_javascript.jpg",
            link: "https://www.udemy.com/course/clonez-le-site-de-cartographie-google-maps-en-javascript/?couponCode=",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 2,
            name: "Passez au Full stack avec Node.js, Express et MongoDB (2021)",
            description: "Un cursus général complété par deux spécialisations, une Backend (Node,Express,Mongo, ...) et l'autre Frontend (Angular)",
            slug: "",
            category: ["formation-frontend", "formation-backend", "formation-developpement-full-stack"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 53,
            imageUrl: "/assets/images/formation/formation_dev_web_full_stack.jpg",
            link: "https://www.udemy.com/course/passez-au-full-stack-avec-nodejs-express-et-mongodb-2021/?couponCode=",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 3,
            name: "Apprendre Symfony 5 et 6 par un blog et un site e-Commerce",
            description: "Maîtrisez Symfony 6 à travers deux grands projets professionnels avec système de paiement en ligne, backoffice, emailing",
            slug: "",
            category: ["formation-backend"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 78,
            imageUrl: "/assets/images/formation/formation_dev_web_symfony.jpg",
            link: "https://www.udemy.com/course/apprendre-symfony-en-creant-un-blog-et-un-site-e-commerce/?couponCode=",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 4,
            name: "WordPress de Débutant à Expert Redoutable de A à Z (en 2021)",
            description: "Créez un blog et un site e-commerce de A à Z avec paiement en ligne, backoffice, SEO, sécurisation, hébergement, etc ...",
            slug: "",
            category: [
                "developpement-avec-cms",
                "formation-developpement-wordpress"
            ],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 25,
            imageUrl: "/assets/images/formation/formation_dev_web_wordpress.jpg",
            link: "https://www.udemy.com/course/wordpress-de-debutant-a-expert-redoutable-de-a-a-z-en-2021/?couponCode=",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 5,
            name: "Formation Complète Développeur WEB (Apprenez en pratiquant)",
            description: "Le Développement Web (par la pratique) de A à Z. Apprenez ✅HTML,✅CSS, ✅Javascript,✅Bootstrap,✅PHP,✅MySQL, ✅Wordpress...",
            slug: "",
            category: [
                "formation-frontend",
                "formation-backend",
                "formation-developpement-full-stack",
                "formation-developpement-web"
            ],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 374,
            imageUrl: "/assets/images/formation/formation_complete_dev_web.jpg",
            link: "https://www.udemy.com/course/formation-complete-developpeur-web/?couponCode=",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 6,
            name: "Clonez le réseau social Facebook Episode 2",
            description: "Dans cette formation, nous apprendrons à créer un réseau social de type Facebook, intégrant plusieurs fonctionnalités de haut niveau, y compris le chat en temps réel.",
            slug: "",
            category: [
                "developpement-frontend",
                "developpement-backend"
            ],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 30,
            imageUrl: "/assets/images/formation/clonez_facebook_episode_2.jpg",
            link: "https://formations.apprenez-a-coder.fr/clonez-le-reseau-social-facebook-episode-2",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 7,
            name: "Clonez le réseau social Facebook Episode 1",
            description: "Dans cette formation, nous apprendrons à créer un réseau social de type Facebook, intégrant plusieurs fonctionnalités de haut niveau, y compris le chat en temps réel.",
            slug: "",
            category: [
                "developpement-frontend",
                "developpement-backend"
            ],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 102,
            imageUrl: "/assets/images/formation/clonez_facebook_episode_1.jpg",
            link: "https://formations.apprenez-a-coder.fr/clonez-le-reseau-social-facebook-episode-1",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 8,
            name: "Apprendre Angular par la création d'un site e-commerce 2021",
            description: "De nombreux projets professionnels pour apprendre, approfondir et devenir un expert incontournable du framework Angular",
            slug: "",
            category: ["developpement-frontend"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 200,
            imageUrl: "/assets/images/formation/formation_dev_web_angular.jpg",
            link: "https://www.udemy.com/course/devenez-expert-incontournable-en-angular-par-la-pratique/?couponCode=",
            status: "Meilleure vente",
            position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 9,
            name: "Formation complète JavaScript de A à Z (par la Pratique)",
            description: "Apprendre le langage JavaScript en partant des bases et en évoluant vers les frameworks populaires Angular, Vue & React",
            slug: "",
            category: ["formation-frontend", "formation-developpement-javascript"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 10,
            imageUrl: "/assets/images/formation/formation_complete_javascript.jpg",
            link: "https://www.udemy.com/course/formation-complete-javascript-par-la-pratique/?couponCode=",
            status: "Meilleure vente", position: 1, tags: [""],
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 10,
            name: "Formation HTML 5 et CSS 3 (pour les débutants)",
            description: "Créez des sites modernes adaptés aux mobiles avec les dernières technologies grid et flexbox, devenez développeur web !",
            slug: "",
            category: ["formation-frontend"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 103,
            imageUrl: "/assets/images/formation/formation_complete_html_et_css.jpg",
            link: "https://www.udemy.com/course/debuter-dans-le-metier-de-developpeur-web/?couponCode=",
            status: "Meilleure vente",
            position: 1,
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 11,
            name: "Formation complète CSS 3 (par la Pratique)",
            description: "Apprendre le langage CSS 3 avec toutes les techniques de mise en page responsive avancées telles que GRID et FLEXBOX",
            slug: "",
            category: ["formation-frontend"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 50,
            imageUrl: "/assets/images/formation/formation_complete_css.jpg",
            link: "https://www.udemy.com/course/formation-complete-css-3-par-la-pratique/?couponCode=",
            status: "Nouveau",
            position: 1,
            createdAt: new Date(),
            updatedAt: new Date()
        },
        {
            _id: 12,
            name: "Débutez avec Angular à partir de Zéro (par la pratique)",
            description: "Apprenez le puissant framework JavaScript Angular et développez des applications professionnels",
            slug: "",
            category: ["formation-frontend"],
            price: 19999,
            soldPrice: 1299,
            authorFullName: "Espero AKPOLI",
            reviewsCount: 109,
            imageUrl: "/assets/images/formation/debutez_avec_angular.jpg",
            link: "https://www.udemy.com/course/debutez-avec-angular-a-partir-de-zero-par-la-pratique/?couponCode=",
            status: "Nouveau",
            position: 1,
            createdAt: new Date(),
            updatedAt: new Date()
        }
    ]
}

export function getFooterLinks() {
    return [
        [
            {
                name: "Formation Complète Développeur Web",
                link: "https://www.udemy.com/course/formation-complete-developpeur-web/"
            },
            {
                name: "Formation Développeur Full Stack",
                link: "https://www.udemy.com/course/passez-au-full-stack-avec-nodejs-express-et-mongodb-2021/"
            },
            {
                name: "Formation Développeur Frontend",
                link: "https://www.udemy.com/course/devenez-expert-incontournable-en-angular-par-la-pratique/?couponCode=082021"
            },
            {
                name: "FormationDéveloppeur Backend",
                link: "https://www.udemy.com/course/apprendre-symfony-en-creant-un-blog-et-un-site-e-commerce/?couponCode=082021"
            }
        ],
        [
            {
                name: "Formation Complète Angular",
                link: "https://www.udemy.com/course/devenez-expert-incontournable-en-angular-par-la-pratique/?couponCode=082021"
            },
            {
                name: "Formation Complète Symfony",
                link: "https://www.udemy.com/course/apprendre-symfony-en-creant-un-blog-et-un-site-e-commerce/?couponCode=082021"
            },
            {
                name: "Formation Complète JavaScript",
                link: "https://www.udemy.com/course/formation-complete-javascript-par-la-pratique/"
            },
            {
                name: "Formation Complète HTML 5 et CSS 3",
                link: "https://www.udemy.com/course/debuter-dans-le-metier-de-developpeur-web/?couponCode=082021"
            }
        ],
        [
            {
                name: "Formation Complète CSS 3",
                link: "https://www.udemy.com/course/formation-complete-css-3-par-la-pratique/"
            },
            {
                name: "Clonez Google Maps avec JavaScript",
                link: "https://www.udemy.com/course/clonez-le-site-de-cartographie-google-maps-en-javascript/"
            },
            {
                name: "Clonez Facebook de A à Z (Episode 1)",
                link: "https://formations.apprenez-a-coder.fr/clonez-le-reseau-social-facebook-episode-1"
            },
            {
                name: "Clonez Facebook de A à Z (Episode 2)",
                link: "https://formations.apprenez-a-coder.fr/clonez-le-reseau-social-facebook-episode-2"
            }
        ],
    ]
}
export function getCategory() {
    return [
        {
            name: "Formation Frontend",
            slug: "formation-frontend"
        },
        {
            name: "Formation Backend",
            slug: "formation-backend"
        },
        {
            name: "Développement Web",
            slug: "formation-developpement-web"
        },
        {
            name: "Développement Full Stack",
            slug: "formation-developpement-full-stack"
        },
        {
            name: "Développement WordPress",
            slug: "formation-developpement-wordpress"
        },
        {
            name: "Développement avec CMS",
            slug: "developpement-avec-cms"
        },
        {
            name: "Développement JavaScript",
            slug: "formation-developpement-javascript"
        }
    ]
}
export function getCategoryBySlug(slug) {
    return getCategory().filter(c => c.slug === slug)[0]
}
export function getCoursesByCategory(category) {
    let course = getCourses()
    //console.log(course);
    course = course.filter(c => c.category.indexOf(category) >= 0)
    return course
}
export function searchCourse(tag) {
    let course = getCourses()
    tag = tag.toLowerCase()
    course = course.filter((course) => {
        const name = course.name.toLowerCase()
        const category = course.category.join().toLowerCase()
        if (name.search(tag) >= 0) {
            return true
        }
        if (category.search(tag) >= 0) {
            return true
        }
        return false
    })
    return course
}

export function slogan() {
    return [
        {
            title: "Un apprentissage qui vous ressemble",
            description: "Des compétences pour aujourd'hui (et demain). Nous vous accompagnons dans vos premiers pas."
        },
        {
            title: "Un apprentissage qui vous ressemble",
            description: "Des compétences pour aujourd'hui (et demain). Nous vous accompagnons dans vos premiers pas."
        }
    ]
}

export function getEmployements() {
    return [
        {
            title: `Développez vos compétences`,
            content: `Choisissez les formations qui vous intéressent et lancez-vous dans l'aventure d'un nouvel apprentissage !`,
            imageUrl: "/assets/images/image-21.png"
        },
        {
            title: `Attestez le suivi des formations`,
            content: `Téléchargez et conservez l'attestation fournie à la fin de chaque formation, qui prouve que vous êtes bien allé(e) jusqu'au bout`,
            imageUrl: "/assets/images/image-20.png"
        },
        {
            title: `Développez vos compétences`,
            content: `Actualisez vos compétences sur les réseaux sociaux professionnels (LinkedIn, ...) et sur votre CV, pour mieux séduire les recruteurs`,
            imageUrl: "/assets/images/image-19.png"
        }
    ]
}
export function getMobileOptions() {
    return [
        {
            options: [
                "Accès au catalogue de formations Mudey",
                "Achats de formations via l’application",
                "Visionnage des formations en streaming",
                "Téléchargement pour visionnage offline",
                "Player vidéo optimisé pour mobiles et tablettes",
                "Historique de vos formations",
                "Synchronisation de l’activité mobile / PC",
                "Gestion de votre compte utilisateur",
                "Accès aux attestations de fin de formation",
            ],
            title: `Aperçu des fonctionnalités`,
            imageUrl: `/assets/images/smartphone-mudey.svg`
        }
    ]
}