import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './AsideListing.css';

export default class AsideListing extends Component{

  constructor(props){
    super(props)
    this.right = props.right
    this.data = props.data 
  }

  render() {
    return (
      <div className="AsideComp AsideListing flex gap-10 aic">
        <div className="aside-left-content">
          {
            this.right ?
            <Fragment>
            <h4>
              {
                this.data.title
              }
            </h4>
            <ul>
              {
                this.data.options.map(option =><li><i className="fa fa-check" ></i> {option}</li>)
              }
            </ul>
            </Fragment>
            :
            <img className="smartphone" src={this.data.imageUrl}/>
          }
        </div>
        <div className="aside-right-content">
        {
            this.right ?
            <img className="smartphone"  src={this.data.imageUrl}/>
            :
            <Fragment>
              <h4>
              {
                this.data.title
              }
            </h4>
            <ul>
              {
                this.data.options.map(option =><li><i className="fa fa-check" ></i> {option}</li>)
              }
            </ul>
            </Fragment>
          }
         
        </div>
      </div>
    )
  };

}


