import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getFooterLinks } from '../../api/api-courses';
import FooterList from '../FooterList/FooterList';
import './Footer.css';

export default class Footer extends Component{

    constructor(props){
        super(props)
        this.state = {coursesFooterLink: []}
    }

    componentDidMount(){
        const coursesFooterLink = getFooterLinks()
        //console.log(coursesFooterLink);
        this.setState({...this.state, coursesFooterLink: coursesFooterLink})
    }

    render() {

        return (
            <footer>
                <div className="footer-title">
                    Développez vos compétences IT avec un catalogue de formations inégalées en  Programmation, Base de données, Réseaux, Scientifique, Sécurité, Bureautique, Virtualisation, OpenSource, IT, Web, CAO et DAO.
                </div>
                <div className="footer-content flex gap-20 aic">
                    <div className="footer-list">
                        <div className="footer-item"><Link to="/mentions-legales">Mentions Légales</Link></div>
                        <div className="footer-item"><Link to="/employabilite">Boostez votre employabilité</Link></div>
                        <div className="footer-item"><Link to="/mobilite">Formez-vous en mobilité</Link></div>
                        <div className="footer-item"><Link to="/finance">Financez vos formations</Link></div>
                    </div>
                    {
                    this.state.coursesFooterLink.length ?
                    this.state.coursesFooterLink.map((link, index) => <FooterList links={link} key={index}/>)
                    :
                    null
                    }
                   
                </div>
                <div className="footer-bottom">
                    Copyright © 2023 Espero-Soft Informatiques
                </div>
            </footer>
        )
    }
}

