import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './Mobilite.css';
import Slider from '../Slider/Slider';
import { getMobileOptions } from '../../api/api-courses';
import AsideListing from '../AsideListing/AsideListing';
import Loading from '../Loading/Loading';

export default class Mobilite extends Component {
  constructor(props) {
    super(props)
    this.state = {mobilities: []}
    this.slider = {
      title: "Formez-vous en mobilité",
      content: `Téléchargez les nouvelles applications mobiles & tablettes pour vous former
      « ou vous voulez, quand vous voulez », avec ou sans connexion internet.`,
      imageUrl: 'url("/assets/images/cover-1.svg" )'
    }
  }
  componentDidMount(){
    window.scrollTo(0,0)
    const mobilities = getMobileOptions()
    this.setState({mobilities: mobilities})
  }
  componentDidUpdate(){
    window.scrollTo(0,0)
  }
  render() {

    return (
      <Fragment>
        <Slider slider={this.slider} />
        <div className="content-title tc">
          <h2>Formez-vous en mobilité</h2>
          <p>Choisissez parmi 1 500 vidéos de cours en ligne. De nouveaux cours sont ajoutés tous les mois</p>
        </div>
        <div className="page flex gap-20 wrap aic">
          <p>
            Avec les applications mobiles Mudey,
            plus besoin de rester assis devant votre
            ordinateur: libérez-vous des contraintes pour
            vous former en toute flexibilité
          </p>

          </div>
          {
            this.state.mobilities.length?
            this.state.mobilities.map((m,index) => <AsideListing right={!!(index%2)} data={m}/>)
            :
            <Loading/>
          }
          
          
      </Fragment>
    )
  }

}