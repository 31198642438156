import { Component, Fragment } from "react";
import { getCourses } from "./api/api-courses";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Modal from "./components/Modal/Modal";
import Container from "./components/Container/Container";
import Slider from "./components/Slider/Slider";
import Mobilite from "./components/Mobilite/Mobilite"
import Finance from "./components/Finance/Finance"
import Employabilite from "./components/Employabilite/Employabilite"
import Legales from "./components/Legales/Legales"
import { Route, Switch } from "react-router-dom";
import Error404 from "./components/Error404/Error404";
import Home from "./components/Home/Home";
import ProductByCategory from "./components/ProductByCategory/ProductByCategory";

export default class App extends Component {

  constructor(props){
    super(props)
    this.state = {courses: [], modal: false}
    this.closeModal = this.closeModal.bind(this)
  }
  componentDidUpdate(){
    window.scrollTo(0,0)
  }
  componentDidMount(){
    window.scrollTo(0,0)
    const courses = getCourses()
    if(courses.length){
      this.setState({...this.state, courses: courses})
    }
    setTimeout(()=>{
     //this.setState({...this.state, modal: true})
    },5000)
  }
  closeModal(){
    //console.log("close");
    this.setState({...this.state, modal: false})
  }

  render(){
    return (
      <Fragment>
        <Header/>
        <Switch>
          <Route exact path="/" render={props =><Home {...this.state} {...props}/>}/>
          <Route exact path="/mentions-legales" render={props =><Legales/>}/>
          <Route exact path="/mobilite" render={props =><Mobilite/>}/>
          <Route exact path="/employabilite" render={props =><Employabilite/>}/>
          <Route exact path="/finance" render={props =><Finance/>}/>
          <Route exact path="/course/:category" 
                 render={props =><ProductByCategory {...this.state} {...props}/>}
          />
          <Route path="/*" render={props => <Error404 {...props}/>} />
        </Switch>
        <Footer/>
        {this.state.modal ? <Modal close={this.closeModal}/>: null}
      </Fragment>
    );
  }
}