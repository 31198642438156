import { httpClient } from "./httpClient";
import { environment } from '../environments/environment';


export default class EntityManager{

    apiUrl = environment.apiUrl
    http = new httpClient()
    
    constructor(){}

    getDatas(entity){
        return this.http.get(this.apiUrl+"/"+entity)
    }

    getDataById(entity, id){
        return this.http.get(this.apiUrl+"/"+entity+"/"+id)
    }
    getCourseByCategorySlug(slug){
        return this.http.get(this.apiUrl+"/course/by/category/"+slug)
    }

    createData(entity,data){
        return this.http.post(this.apiUrl+"/"+entity, data)
    }
    searchData(entity,params){
        return this.http.get(this.apiUrl+"/"+entity+"/search?"+params)
    }

    updateData(entity,id, data){
        return this.http.put(this.apiUrl+"/"+entity+"/"+id, data)
    }

    deleteData(entity,id){
        return this.http.delete(this.apiUrl+"/"+entity+"/"+id)
    }

}