import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './AsideComp.css';

export default class AsideComp extends Component {

  constructor(props){
    super(props)
    this.right = props.right
    this.data = props.data 
  }

  render() {
    return (
      <div className="AsideComp flex gap-10 aic">
        <div className="aside-left-content">
          {
            this.right ?
            <img src={this.data.imageUrl} />
            :
            <Fragment>
              <h4>{this.data.title}</h4>
              <p>{this.data.content}</p>

            </Fragment>
          }
        </div>
        <div className="aside-right-content">
        {
            !this.right ?
            <Fragment>

              <img src={this.data.imageUrl} />
            </Fragment>
            :
            <Fragment>
              <h4>{this.data.title}</h4>
              <p>{this.data.content}</p>

            </Fragment>
          }
         
        </div>
      </div>
    )
  }

}

